import en from './lang/en.json'
import fr from './lang/fr.json'
import de from './lang/de.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
    de,
  },
}))
